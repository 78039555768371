<template>
	<div class="layout-profile">
		<div>
			<Avatar :image="avatar" size="xlarge" shape="circle" alt="" />
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{userCredentials.user.name}}</span>
			<i class="pi pi-fw pi-angle-down"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button @click="$router.push('/dashboard/account')" class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <!-- <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li> -->
				<li><button @click="$e.emit('logout')" class="p-link"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>

export default {
	props: {
		userCredentials: {},
	},
	data() {
		return {
			expanded: false,
			avatar: (this.userCredentials.user.avatar) ? process.env.VUE_APP_ASSETS_FOLDER + '/user_avatar/' + this.userCredentials.user.avatar : process.env.VUE_APP_DEFAULT_AVATAR,
		}
	},
	methods: {
		onClick(event){
			this.expanded = !this.expanded;
			event.preventDefault();
		}
	},
}
</script>

<style scoped>

</style>