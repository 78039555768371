<template>
	<div class="layout-footer">
		<span v-html="settings.general.footer" class="footer-text"></span>
	</div>
</template>

<script>
export default {
	props: ['settings'],
	data() {
		return {
			
		}
	}
}
</script>

<style scoped>

</style>