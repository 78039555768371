<template>
	<div :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" :src="logo" />
                    </router-link>
                </div>

                <AppProfile :userCredentials="userCredentials" />
                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

		<div class="layout-main">
			<router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component ref="activePage" :key="$route.path" :is="Component" :settings="settings" :userCredentials="userCredentials" />
                </transition>
            </router-view>
		</div>

		<!-- <AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode" @layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange"/> -->

		<AppFooter :settings="settings" />
	</div>
</template>

<script>
import AppTopBar from './_Topbar.vue';
import AppProfile from './_Profile.vue';
import AppMenu from './_Menu.vue';
// import AppConfig from './_Config.vue';
import AppFooter from './_Footer.vue';

export default {
    name: 'dashboard',
    props: ['settings'],
    data() {
        return {
            userCredentials: this.$store.getters.userCredentials,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : [
                {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard/main'},
                {
                    label: 'Website', icon: 'pi pi-fw pi-cog', visible: () => this.$can('slideshow-list|post-list'),
                    items: [
                        {label: 'Slideshow', icon: 'pi pi-fw pi-image', to: '/dashboard/slideshow', visible: () => this.$can('slideshow-list'),},
                        {
                            label: 'Halaman', icon: 'pi pi-fw pi-book', visible: () => this.$can('page-list|page-add'),
                            items: [
                                {label: 'Semua', icon: 'pi pi-fw pi-book', to: '/dashboard/pages', visible: () => this.$can('page-list'), },
                                {label: 'Tambah Baru', icon: 'pi pi-fw pi-plus', to: '/dashboard/page-new', visible: () => this.$can('page-add'), },
                            ]
                        },
                        {
                            label: 'Berita', icon: 'pi pi-fw pi-globe', visible: () => this.$can('post-list|post-add|category-list'),
                            items: [
                                {label: 'Semua', icon: 'pi pi-fw pi-globe', to: '/dashboard/posts', visible: () => this.$can('post-list'), },
                                {label: 'Tambah Baru', icon: 'pi pi-fw pi-plus', to: '/dashboard/post-new', visible: () => this.$can('post-add'), },
                                {label: 'Kategori', icon: 'pi pi-fw pi-tag', to: '/dashboard/category', visible: () => this.$can('category-list'), },
                                // {label: 'Komentar', icon: 'pi pi-fw pi-home', to: '/dashboard/comment', visible: () => this.$can('post-list'), },
                            ]
                        },
                    ]
                },
                {label: 'Member', icon: 'pi pi-fw pi-users', to: '/dashboard/member', visible: () => this.$can('member-list'), },
                {label: 'Kegiatan', icon: 'pi pi-fw pi-calendar-times', visible: () => this.$can('kegiatan-list|sertifikat-list|member-sertifikat'), 
                    items: [
                        {label: 'Kegiatan', icon: 'pi pi-fw pi-calendar-times', to: '/dashboard/kegiatan', visible: () => this.$can('kegiatan-list'), },
                        {label: 'Sertifikat', icon: 'pi pi-fw pi-file', to: '/dashboard/sertifikat', visible: () => this.$can('sertifikat-list'), },
                        {label: 'Sertifikat', icon: 'pi pi-fw pi-file', to: '/dashboard/sertifikat-member', visible: () => this.$can('member-sertifikat'), },
                    ]
                },
                {label: 'Print KTA', icon: 'pi pi-fw pi-print', to: '/dashboard/member-print-kta', visible: () => this.$can('member-self-print-kta'), },
                {label: 'User Profile', icon: 'pi pi-fw pi-user', to: '/dashboard/account', visible: () => this.$can('account-view'),},
                {label: 'User Profile', icon: 'pi pi-fw pi-user', to: '/dashboard/account-member', visible: () => this.$can('account-member-view'),},
                {
                    label: 'Setting', icon: 'pi pi-fw pi-cog', visible: () => this.$can('user-list|role-list|role-dashboard-list|document-template-list|setting-general|setting-other'),
                    items: [
                        {label: 'User Management', icon: 'pi pi-fw pi-users', to: '/dashboard/user', visible: () => this.$can('user-list'), },
                        {
                            label: 'Roles', icon: 'pi pi-fw pi-sitemap', visible: () => this.$can('role-list|role-dashboard-list'),
                            items: [
                                {label: 'Role', icon: 'pi pi-fw pi-sitemap', to: '/dashboard/role', visible: () => this.$can('role-list'), },
                                {label: 'Role Dashboard', icon: 'pi pi-fw pi-sitemap', to: '/dashboard/role-dashboard', visible: () => this.$can('role-dashboard-list'), },
                                {label: 'Regional Role', icon: 'pi pi-fw pi-sitemap', to: '/dashboard/regional-role', visible: () => this.$can('regional-role-list'), },
                                {label: 'Regional User', icon: 'pi pi-fw pi-sitemap', to: '/dashboard/regional-user', visible: () => this.$can('regional-user-list'), },
                            ]
                        },
                        {label: 'Document Template', icon: 'pi pi-fw pi-file', to: '/dashboard/document-template', visible: () => this.$can('document-template-list'), },
                        {label: 'General', icon: 'pi pi-fw pi-list', to: '/dashboard/setting-general', visible: () => this.$can('setting-general'), },
                        {label: 'Other', icon: 'pi pi-fw pi-list', to: '/dashboard/setting-other', visible: () => this.$can('setting-other'), },
                    ]
                },
                {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => this.$e.emit('logout'), },
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            // this.$toast.removeAllGroups();
        },
    },
    methods: {
        logout(){
            this.$Progress.start();
            this.$httpAuth.get('/api/auth/logout')
            .then((response) => {
                this.$Progress.finish();
                this.$store.commit('logout');
                this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                this.$router.push('/login');
            })
            .catch((error) => {
                this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        logo() {
            return (this.settings.general.logo) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.logo : process.env.VUE_APP_DEFAULT_LOGO;
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        // 'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    },
    beforeCreate(){
        this.$e.on('logout', () => this.logout());
        this.$e.on('getUserCredentials', () => {
            this.$store.dispatch('getUserCredentials').then((response) => {
                this.userCredentials = response.data;
            })
        });
    },
    created(){
    },
    unmounted(){
        this.$e.all.clear();
    },
}
</script>

<style>
.layout-logo img {
    height: 50px;
}
</style>